/* ###### Image ###### */

.main-img-user {
  display: block;
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
  }
}

.img-fit-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-thumbnail .caption {
  padding: 15px;
}

.img-flag {
  width: 25px;
  height: 12px;
  margin-top: -4px;
}

.img-sm {
  width: 64px;
  min-width: 64px;
  height: 64px;
  border-radius: 5px;
  margin-right: 20px;
  border: 1px solid $background;
  background: #f4f4fb;
  padding: 10px;
}

/* ###### Image ###### */

/* ###### Avatar ###### */

.avatar-list {
  .avatar {
    margin-bottom: .5rem;

    &:not(:last-child) {
      margin-right: .5rem;
    }
  }

  margin: 0 0 -.5rem;
  padding: 0;
  font-size: 0;
}

.avatar {
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  font-weight: 600;
  font-size: 16px;
}

.main-avatar {
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  font-weight: 600;
  font-size: 16px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 3px;
    width: 6px;
    height: 6px;
    background-color: #a8afc7;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95);
    border-radius: 100%;
  }

  &.online::after {
    background-color: $success;
  }
}

.avatar-xs {
  width: 24px;
  height: 24px;
  font-size: 11px;

  &::after {
    width: 5px;
    height: 5px;
  }
}

.avatar-sm {
  width: 32px;
  height: 32px;
  font-size: 14px;

  &::after {
    width: 7px;
    height: 7px;
  }
}

.avatar-md {
  width: 40px;
  height: 40px;
  font-size: 24px;

  &::after {
    width: 9px;
    height: 9px;
    right: 2px;
    bottom: 2px;
  }
}

.avatar-lg {
  width: 64px;
  height: 64px;
  font-size: 28px;

  &::after {
    width: 10px;
    height: 10px;
    bottom: 3px;
    right: 4px;
  }
}

.avatar-xl {
  width: 72px;
  height: 72px;
  font-size: 36px;

  &::after {
    width: 11px;
    height: 11px;
    bottom: 4px;
    right: 5px;
    box-shadow: 0 0 0 2.5px $white;
  }
}

.avatar-xxl {
  width: 100px;
  height: 100px;
  font-size: 56px;

  &::after {
    width: 13px;
    height: 13px;
    bottom: 6px;
    right: 7px;
    box-shadow: 0 0 0 3px $white;
  }
}


.demo-avatar-group {
  display: flex;
  align-items: center;

  .main-img-user, .main-avatar {
    flex-shrink: 0;
  }

  .main-img-user + {
    .main-img-user, .main-avatar {
      margin-left: 5px;
    }
  }

  .main-avatar + {
    .main-img-user, .main-avatar {
      margin-left: -5px;
    }
  }
}

@media (min-width: 576px) {
  .demo-avatar-group {
    .main-img-user + {
      .main-img-user, .main-avatar {
        margin-left: -9px;
		    border: 2px solid $white;
      }
    }

    .main-avatar + {
      .main-img-user, .main-avatar {
       margin-left: -9px;
		   border: 2px solid $white;
      }
    }
  }
}

@media (min-width: 576px) {
  #shapes .demo-avatar-group {
    .main-img-user + {
      .main-img-user, .main-avatar {
        margin-left: 9px;
		    border: 0px solid $white;
      }
    }
   }
 }
/* ###### Avatars ###### */