/* ###### List-group ###### */

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  color: #3c4858;
}

.list-group-item-action {
  width: 100%;
  color: #3c4858;
  text-align: inherit;

  &:hover, &:focus {
    color: #3c4858;
    text-decoration: none;
    background-color: $light;
  }

  &:active {
    color: #8f9cc0;
    background-color: $background;
  }

  h5 {
    font-size: 16px;
  }

  p {
    font-size: 14px;
  }
}

.list-group-item {
  position: relative;
  display: block;
  padding: 16px  15px;
  margin-bottom: -1px;
  background-color: $white;
  border: 1px solid $border;

  &.disabled, &:disabled {
    color: #a8afc7;
    pointer-events: none;
    background-color: $white;
  }
}

.list-group-horizontal {
  flex-direction: row;

  .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;

    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-top-right-radius: 0;
    }

    &:last-child {
      margin-right: 0;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 0;
    }
  }
}

.listgroup-example2 ul ul {
  list-style-type: circle;
  margin: 10px 0 0 0;
}

.listgroup-example ul, .listgroup-example2 ul {
  list-style-type: none;
}

.listgroup-example ul li, .listgroup-example2 ul li {
  padding: 5px 5px;
}

.listgroup-example .list-group.checked .list-group-item {
  border: 0;
}

.listorder {
  position: relative;
  margin-bottom: 0;
  border: 0;
  list-style-type: decimal;
  list-style-position: inside;
  padding: 7px 11px !important;
  margin-right: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;

    .list-group-item {
      margin-right: -1px;
      margin-bottom: 0;

      &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
      }

      &:last-child {
        margin-right: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 0;
      }
    }
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;

    .list-group-item {
      margin-right: -1px;
      margin-bottom: 0;

      &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
      }

      &:last-child {
        margin-right: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 0;
      }
    }
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;

    .list-group-item {
      margin-right: -1px;
      margin-bottom: 0;

      &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
      }

      &:last-child {
        margin-right: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 0;
      }
    }
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;

    .list-group-item {
      margin-right: -1px;
      margin-bottom: 0;

      &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
      }

      &:last-child {
        margin-right: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.list-group-flush {
  .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;

    &:last-child {
      margin-bottom: -1px;
    }
  }

  &:first-child .list-group-item:first-child {
    border-top: 0;
  }

  &:last-child .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }
}

.list-group-item-primary {
  color: $primary !important;
  background-color: $primary-08 !important;

  &.list-group-item-action {
    &:hover, &:focus {
      color: $primary;
      background-color: $primary-08;
    }

    &.active {
      color: $white;
      background-color: $primary;
      border-color: $primary;
    }
  }
}

.list-group-item-secondary {
  color: $secondary !important;
  background-color: #f9e5db !important;

  &.list-group-item-action {
    &:hover, &:focus {
      color: #3f4654;
      background-color: #cacfdb;
    }

    &.active {
      color: $white;
      background-color: #3f4654;
      border-color: #3f4654;
    }
  }
}

.list-group-item-success {
  color: #1f5c01 !important;
  background-color: #c8e9b8 !important;

  &.list-group-item-action {
    &:hover, &:focus {
      color: #1f5c01;
      background-color: #b9e3a5;
    }

    &.active {
      color: $white;
      background-color: #1f5c01;
      border-color: #1f5c01;
    }
  }
}

.list-group-item-info {
  color: #0c5460 !important;
  background-color: #bee5eb !important;

  &.list-group-item-action {
    &:hover, &:focus {
      color: #0c5460;
      background-color: #abdde5;
    }

    &.active {
      color: $white;
      background-color: #0c5460;
      border-color: #0c5460;
    }
  }
}

.list-group-item-warning {
  color: #856404 !important;
  background-color: #ffeeba !important;

  &.list-group-item-action {
    &:hover, &:focus {
      color: #856404;
      background-color: #ffe8a1;
    }

    &.active {
      color: $white;
      background-color: #856404;
      border-color: #856404;
    }
  }
}

.list-group-item-danger {
  color: #721c24 !important;
  background-color: #f5c6cb !important;

  &.list-group-item-action {
    &:hover, &:focus {
      color: #721c24;
      background-color: #f1b0b7;
    }

    &.active {
      color: $white;
      background-color: #721c24;
      border-color: #721c24;
    }
  }
}

.list-group-item-light {
  color: #8f9cc0 !important;
  background-color: $light !important;

  &.list-group-item-action {
    &:hover, &:focus {
      color: #8f9cc0;
      background-color: $background;
    }

    &.active {
      color: $white;
      background-color: #7f7f81;
      border-color: #7f7f81;
    }
  }
}

.list-group-item-dark {
  color: #1f2533 !important;
  background-color: #c8ccd3 !important;

  &.list-group-item-action {
    &:hover, &:focus {
      color: #1f2533;
      background-color: #babfc8;
    }

    &.active {
      color: $white;
      background-color: #1f2533;
      border-color: #1f2533;
    }
  }
}

.list-group-item.active {
    color: $white;
    background-color: $primary;
    border-color: $primary;
}
/* ###### List-group ###### */