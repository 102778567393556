/***Start Horizontal Hover Styles ***/
@media (min-width: 992px) {
	.horizontalmenu-hover .menu-nav {
		.nav-item:hover ul.nav-sub {
			display: block !important;
		}

		.nav-sub {
			.nav-sub-link {

				&.with-sub .angle,
				&.sub-with-sub .angle {
					display: none !important;
				}
			}

			.nav-sub-item:hover .sub-nav-sub {
				display: block;
			}
		}

		.sub-nav-sub {
			.sub-nav-sub {
				display: none !important;
			}

			.nav-sub-item:hover .sub-nav-sub {
				display: block !important;
			}
		}

		.nav-item.active .nav-sub {
			display: none !important;
		}
	}
}

/***End Horizontal Hover Styles ***/

/***Start Horizontal Styles ***/

@media (min-width: 992px) {
	.horizontalmenu {
		.sidemenu-logo {
			display: none;
		}

		.hor-menu {
			.nav-item .nav-link .side-badge {
				display: none;
			}

			.nav {
				display: flex;
				flex-wrap: nowrap;
				align-items: center;

				.sidemenu-label {
					display: flex;
				}

				.nav-header {
					display: none;
				}
			}
		}

		.main-body-1 .nav-item.active .nav-link .shape1,
		.main-body-1 .nav-item.active .nav-link .shape2 {
			display: none;
		}

		.main-navbar .nav-item.show .nav-link::before {
			display: none;
		}

		.main-navbar.hor-menu .container,
		.main-content.hor-content .container,
		.container {
			max-width: 85%;
		}
	}

	.horizontalmenu .hor-menu .nav .nav-item {
		padding-right: 0px;
	}

	.horizontalmenu .main-navbar .nav-link i.angle {
		display: none;
	}

	.horizontalmenu .hor-logo {
		display: block;
	}

	.horizontalmenu .main-navbar .nav-item .nav-sub li:not(.nav-sub-item) {
		display: none !important;
	}

	.horizontalmenu .nav-sub.open .nav-sub-link.with-sub .angle {
		display: none;
	}

	.horizontalmenu .nav-sub .nav-sub-link.sub-with-sub .angle {
		display: none;
	}

	.horizontalmenu .main-header-menu-icon {
		display: none;
	}

	.horizontalmenu {
		.hor-menu {
			li {
				&.nav-item .nav-link {
					padding-right: 10px;
					padding: 10px 7px 10px 7px !important;
				}
			}

			.nav-link .hor-icon {
				font-size: 17px;
				line-height: 0;
				margin-right: 10px;
				width: 20px;
				height: 35px;
				line-height: 35px;
				text-align: center;
				border-radius: 50%;
				color: $white-6;
				fill: $white-6;
			}
		}

		.main-navbar .nav-sub-item {
			position: relative;
			display: block;
			margin-left: 0;
			padding: 7px 0;
		}

		.menu-nav .nav-item {
			transition-duration: 0.25s;

			ul.nav-sub::before {
				content: "";
				position: absolute;
				top: -8px;
				left: 36%;
				width: 15px;
				height: 15px;
				border: 1px solid transparent;
				border-top-color: $dark-border;
				border-left-color: $dark-border;
				transform: rotate(45deg);
				background-color: $dark-theme;
				z-index: 901;
				display: block;
				transition-duration: 0.25s;
			}
		}

		.main-navbar .nav-sub {
			top: 56px;
		}

		.main-navbar {
			padding: 0;
		}
	}

	.main-navbar.hor-menu.ps {
		overflow: inherit;
	}

	.horizontalmenu .hor-menu {
		.main-container-1 {
			overflow: hidden;
		}

		.main-body-1 {
			position: static;
		}

		.menu-nav {
			.nav-item {
				position: static;
			}

			.nav-sub {
				left: auto;
			}
		}
	}

	.hor-menu .slide-left,
	.hor-menu .slide-right {
		position: absolute;
		top: 15px;
		padding: 6px;
		color: $color;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1;
		cursor: pointer;
		border: 1px solid $border;
		border-radius: 50px;
		background-color: $white;
	}

	.main-navbar .nav-sub-item>.sub-nav-sub {
		left: 158px;
		top: -10px;
		display: none;
		padding: 0 0 0 12px;
	}

	.main-navbar .sub-nav-sub {
		position: absolute;
		width: 180px;
		background-color: $dark-theme;
		border: 1px solid $dark-border;
		padding: 8px 20px !important;
		z-index: 900;
		border-radius: 11px;
		box-shadow: 0 2px 4px rgb(16 35 59 / 15%);
	}

	.main-navbar .nav-sub-link {
		font-size: 0.8125rem;
		margin-left: 18px;
		padding-left: 8px;
	}

	.main-navbar .nav-sub-link.sub-with-sub::after {
		font-family: "Ionicons";
		font-weight: 400;
		display: inline-block;
		position: relative;
		margin-left: auto;
		color: $white-4;
	}

	.main-navbar .nav-sub-link.sub-with-sub::after {
		content: "\f3d1";
		font-size: 12px;
		margin-right: 3px;
		top: -2px;
	}
}

.horizontalmenu .vertical-switcher {
	display: none !important;
}

.ltr {
	#slide-right {
		position: absolute;
		right: 20px;
		left: auto;
	}

	#slide-left {
		position: absolute;
		left: 20px;
		right: auto;
	}
}

.horizontalMenucontainer {
	background-size: cover;
	overflow: hidden;
	background-attachment: fixed;
	background-position: 50% 0;
	background-repeat: no-repeat;
}

@media (max-width: 991.98px) {
	.main-body-1 .nav-item.active {
		.nav-link.with-sub.active {
			.angle {
				display: none;
			}
		}
	}

	.main-navbar-show .main-navbar {
		transform: translateX(0) !important;
		visibility: visible;
	}

	.horizontalmenu {
		.hor-menu {
			top: 60px !important;
		}

		.main-body-1 {
			padding: 0;
		}

		.main-body-1 .nav {
			flex-direction: column;
			padding: 0 0 0 15px;
			margin: 0 auto;
			justify-content: center;
			margin-top: 0;
		}

		.main-body-1 .nav-item {
			position: relative;
			display: block;
		}

		.hor-menu .nav-item {
			transition-duration: 0.25s;
			margin: 6px 0;
		}

		.main-body-1 li.nav-item.active .nav-link {
			background-color: $background;
			position: relative;
			z-index: 1;
			margin: 10px auto;
			text-align: center;
			border-radius: 30px 0 0 30px;
			margin-left: 0;
			border: 0px solid transparent;
			border-right: 0px;
			width: inherit;
			padding: 4px;
		}

		.main-body-1 .nav-link {
			display: flex;
			align-items: center;
			padding: 4px;
			font-weight: 400;
			font-size: 14px;
			color: $white-4;
		}

		.main-body-1 .nav-item.active .nav-link:before,
		.main-body-1 .nav-item.active .nav-link:after {
			border-right: 20px solid $dark-theme;
			display: block !important;
		}

		.main-body-1 .nav-item.active .nav-link:before {
			content: "";
			position: absolute;
			top: -30px;
			z-index: 9;
			right: 0px;
			bottom: 0;
			height: 30px;
			border-top-color: transparent;
			border-left-color: transparent;
			border-bottom: transparent;
			border-radius: 0px 0px 48px 0px;
		}

		.main-body-1 .nav-item.active .nav-link:after {
			content: "";
			position: absolute;
			top: 43px;
			z-index: 0;
			right: 0px;
			bottom: 0;
			height: 30px;
			border-top-color: transparent;
			border-left-color: transparent;
			border-bottom: transparent;
			border-radius: 0px 48px 0px 0px;
		}

		.main-body-1 .nav-item.active .nav-link .shape1,
		.main-body-1 .nav-item.active .nav-link .shape2 {
			background: $background;
		}

		.main-body-1 .nav-item.active .nav-link .shape1 {
			position: absolute;
			top: -30px;
			width: 20px;
			height: 60px;
			right: 0px;
		}

		.main-body-1 .nav-item.active .nav-link .shape2 {
			position: absolute;
			top: 35px;
			width: 20px;
			height: 30px;
			right: 0px;
		}

		.main-body-1 li.active .hor-icon {
			font-size: 18px;
			line-height: 0;
			margin-right: 10px;
			width: 35px;
			height: 35px;
			line-height: 35px;
			text-align: center;
			border-radius: 50%;
			box-shadow: 0 5px 10px $black-2;
			color: $white;
		}

		.main-body-1 li.active .hor-icon {
			background: $primary;
		}

		.main-body-1 .nav-link.with-sub {
			position: relative;
		}

		.main-body-1 .nav-link {
			display: flex;
			align-items: center;
			padding: 4px !important;
			font-weight: 400;
			font-size: 14px;
			color: $white-4;
		}

		.hor-menu i.angle {
			transform-origin: center;
			position: absolute;
			top: 15px;
			right: 20px;
			font-size: 12px;
		}

		.main-body-1 .nav-item+.nav-item {
			margin-top: -1px;
		}

		.main-navbar .nav-item {
			padding-right: 0;
		}

		.main-body-1 .nav-label {
			text-transform: uppercase;
			font-size: 11.49px;
			font-weight: 500;
			letter-spacing: 0.5px;
			color: $white-3;
			padding: 10px 0px 0 0;
		}
		.main-body-1 .nav-link .hor-icon {
			font-size: 17px;
			line-height: 0;
			margin-right: 10px;
			width: 35px;
			height: 35px;
			line-height: 35px;
			text-align: center;
			border-radius: 50%;
		}

		.main-navbar .nav-label {
			margin-bottom: 00;
		}

		.main-navbar .nav-link i {
			margin-right: 0;
		}

		.main-body-1 .nav-sub {
			list-style: none;
			padding: 0;
			margin-left: 1.2rem;
		}

		.hor-menu .nav-sub {
			display: none;
			overflow: hidden;
		}

		.main-body-1 .nav-sub-link {
			display: flex;
			align-items: center;
			color: $white-4;
			font-size: 13px;
			padding: 8px 0;
			position: relative;
		}

		.hor-menu .nav-sub-link {
			transition: all 0.25s;
		}

		.main-body-1 .nav-sub .nav-sub-link:before {
			content: "\e048";
			font-family: "typicons";
			font-style: normal;
			font-weight: normal;
			text-decoration: inherit;
			top: 8px;
			font-size: 12px;
			opacity: 0.4;
			margin-right: 22px;
			color: $white-8;
			position: initial;
		}

		.hor-menu .nav-item.show>.nav-sub {
			display: block;
		}

		.hor-menu .sub-nav-sub {
			display: none;
		}

		.main-navbar .nav-sub-item {
			padding: 0 0;
		}

		.main-body-1 .nav-sub {
			margin-left: 1.2rem;
			padding: 0;
		}

		.hor-menu .nav-link:hover .sidemenu-label {
			margin-left: 5px;
			transition: margin-left 0.45s ease;
		}

		.main-navbar .nav-link {
			height: auto;
		}

		.hor-menu .nav-item.show i.angle {
			transform: rotate(90deg);
			position: absolute;
			top: 11px;
			right: 20px;
			font-size: 12px;
		}
	}

	.main-navbar .nav-item.active .nav-link {
		color: $primary  !important;
	}
}

@media screen and (max-width: 991px) and (min-width: 768px) {
	.horizontalmenu {
		.main-content.hor-content {
			margin-top: 60px;
		}
	}
}

@media (min-width: 992px) {
	.main-header.hor-header.sticky-pin {
		height: 0;
	}
}

@media (max-width: 991px) {

	.main-navbar .nav-sub-item.active>.nav-sub-link.active:before {
		color: $white;
		opacity: 1;
	}

}

/***End Horizontal Styles ***/