/* ###### Perfect-scroll  ###### */

.ps {
  overflow: hidden;

  > .ps__rail-y {
    width: 5px;
    background-color: rgba(28, 39, 60, 0.04);
    z-index: 10;
    position: absolute;
    left: auto !important;
    right: 0;
    opacity: 0;
    transition: opacity 0.2s;

    > .ps__thumb-y {
      position: absolute;
      border-radius: 0;
      width: 3px;
      left: 1px;
      background-color: tranparent;
    }
  }

  &.ps--active-y {
    &:hover > .ps__rail-y, &:focus > .ps__rail-y {
      opacity: 0;
      right: -1px !important;
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .ps > .ps__rail-y {
    transition: none;
  }
}

/* ###### Perfect-scroll  ###### */