/* ###### Cryptocurrencies  ###### */

.crypto-transcation.list-unstyled li {
  display: flex;
  margin: 17px;
  padding: 15px 10px;
  border: 1px solid $background;
  border-radius: 6px;
}

.toolbar .btn {
  background: $white;
  color: #222;
  border: 1px solid #e7e7e7;
  border-bottom: 2px solid #ddd;
  border-radius: 4px;
  padding: 2px 17px;
}

.crypto-card {
  position: relative;
  background-color: $white;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23eaedf7' fill-opacity='0.4' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.btn-default.active {
  background: $primary;
  color: $white;
  border-bottom: 2px solid $primary-hover;
}

@media (max-width: 991px) {
  .card-bitcoin .media {
    display: flex;
    align-items: flex-start;
    overflow-x: scroll;
  }
}

.card-bitcoin {
  .media-icon {
    display: inline-table;
    text-align: center;
    line-height: 42px;
  }

  .media-body {
    margin-left: 20px;
    margin-bottom: 10px;
  }

  .row {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;

    >div {
      flex-shrink: 0;
    }
  }

  label {
    margin-bottom: 2px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: .5px;
    color: #8587a7;
  }

  p {
    margin-bottom: 0;
    color: #1c273c;
    font-weight: 700;
    font-size: 16px;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
}

.crypto-wallet {
  position: relative;

  .chart-circle-value {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column;
    line-height: 1;
    width: 130px;
    height: 130px;
    line-height: 70px;
    border-radius: 50%;
    font-size: 46px;

    &.circle-style {
      border: 2px dashed $primary-07;
      background: #fcfcff;
    }
  }
}

.cryp-icon {
  width: 25px;
  height: 25px;
  font-size: 15px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
}

.img-bg {
  background: url(../img/media/Stars.png);
  background-position: right;
  background-size: auto;
  background-repeat: no-repeat;
}

.crypto-icon {
  width: 40px;
  height: 40px;
  font-size: 15px;
  border-radius: 50px;
  line-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
}

.buy-sell .form {
  border: 1px solid $black-1;
  background: $black-2;
  padding: 15px 0;
  border-radius: 4px;
}

.bg-background2 {
  background: url(../img/media/4046534.jpg);
  background-size: cover;
  background-position: center;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    display: block;
    z-index: 1;
    top: 0;
    background: rgba(98, 89, 202, 0.87);
  }

  .header-text {
    position: relative;
    z-index: 1;
  }
}

.buy-sell {
  .form-control {
    height: 45px;
  }

  .select2-container--default .select2-selection--single {
    height: 45px;

    .select2-selection__rendered {
      line-height: 43px;
    }

    .select2-selection__arrow {
      top: 3px;
    }
  }
}

.crypto-card .chart-wrapper {
  position: relative;
  right: 0;
  bottom: -13px;
  left: -26px;
  width: 130%;
}

.exchange-icon {
  position: absolute;
  height: 50px;
  width: 50px;
  background: $success;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  top: -22px;
  left: -51px;
}

.crypto-btc-usd .nav-link {
  padding: 0;
  margin: 0 3px;
  width: 33px;
  height: 28px;
  line-height: 28px;
  text-align: center;

  &.active {
    background: $primary;
    border-radius: 3px;
    color: $white;
  }
}

.payment-list {
  list-style-type: none;
  display: flex;
  padding-left: 0;

  .payment-item {
    font-size: 14px;
    text-decoration: none;
    color: #c2c6d4;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    list-style: none;
    outline: none;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    text-transform: capitalize;
    display: inline-block;
    margin: 0 1%;
    width: 18%;

    i {
      width: 100%;
      height: 66px;
      display: block;
      border: 1px solid #e6e9f3;
      padding: 15px 0px;
      margin-bottom: 15px;
      cursor: pointer;
      font-size: 30px;
      border-radius: 4px;
    }
  }
}

.payment-item.active {
  i {
    border-color: $success;
    color: $success;
  }

  span {
    color: $success;
  }
}

.cryptoicon {
  width: 23px;
  height: 23px;
  font-size: 13px;
  border-radius: 50px;
  align-items: center;
  display: inline-block !important;
  justify-content: center;
  line-height: 23px;
  text-align: center;
  margin-right: 15px;
}

.payment-type {
  display: flex;

  input {
    display: none;
  }
}

.payment-cards {
  position: relative;
  color: #707894;
  background-color: transparent;
  font-size: 26px;
  text-align: center;
  height: 87px;
  line-height: 46px;
  display: block;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid $background;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 10px;
  border-radius: 3px;
  font-weight: 500;

  img {
    position: absolute;
    top: 37px;
    opacity: 0.8;
    height: 32px;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
    left: 0;
    right: 0;
  }
}

input:checked+label.payment-cards {
  border-color: $primary  !important;
  position: relative !important;
  display: block !important;
  border-width: 1px;
  color: $primary;
}


.form input:checked+label {
  &:after {
    content: "";
    width: 20px;
    height: 20px;
    line-height: 17px;
    border-style: solid;
    border-width: 0 35px 35px 0px;
    border-color: $primary $primary transparent;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }

  &:before {
    content: "\2713";
    z-index: 999;
    position: absolute;
    top: -11px;
    right: 4px;
    font-size: 14px;
    color: $white;
  }
}


.crypto-buysell-card {
  .nav.panel-tabs li {
    .active:before {
      position: absolute;
      top: 60px;
      left: 50%;
      margin-left: -10px;
      width: 0;
      height: 0;
      border: solid transparent;
      border-width: 10px;
      border-top-color: $primary;
      content: '';
      pointer-events: none;
    }

    a {
      position: relative;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 4;

      &.active {
        background: $primary;
        color: $white;
      }
    }

    position: relative;
    z-index: 1;
    display: block;
    margin: 0;
    text-align: center;
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
    line-height: 4;
    background: $white;
  }

  .select2-container--default .select2-selection--single {
    height: 46px;

    .select2-selection__rendered {
      line-height: 45px;
    }
  }
}

.wallet {

  .input-group-addon-right,
  .input-group-addon {
    padding: .5rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;
    color: #b7bec5;
    text-align: center;
    border: 1px solid #e3ebf3;
    border-radius: 0 .25rem .25rem 0;
  }
}

.crypto-wallet .clipboard-icon {
  background-color: $primary  !important;
  border-color: $primary  !important;
  border-radius: 0 4px 4px 0 !important;
  top: 0;
  right: 0;
  font-size: 14px;
  background: $primary;
  border: 1px solid $primary;
  cursor: pointer;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 2.3 !important;
  color: $white;
  position: relative;

  &:hover,
  &:focus {
    background-color: $primary  !important;
    border-color: $primary  !important;
    border-radius: 0 4px 4px 0 !important;
    top: 0;
    right: 0;
    font-size: 14px;
    background: $primary;
    border: 1px solid $primary;
    cursor: pointer;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 2.3 !important;
    color: $white;
    position: relative;
  }
}

.wallet {
  .clipboard-icon {
    top: 0;
    right: 0;
    position: relative;
    padding: 11px 17px;
    background: #f2f1fd;
    border-radius: 0 4px 4px 0 !important;
    border-left: 0;
    height: 46px;
  }

  .input-group-addon-left {
    padding: 12px 17px;
    background: #f2f1fd;
    border-radius: 4px 0 0 4px !important;
    height: 46px;
    border: 1px solid #e3ebf3;
    border-right: 0;
  }

  .input-group i {
    font-size: 20px;
  }
}

.transcation-crypto {
  .table {

    th,
    td {
      padding: 17px 15px;
      margin: 0 14px;
    }

    &.dataTable {

      th,
      td {
        border-left: 0;
      }
    }
  }

  table.dataTable thead {

    .sorting:before,
    .sorting_asc:before,
    .sorting_desc:before,
    .sorting_asc_disabled:before,
    .sorting_desc_disabled:before {
      right: 1em;
      content: "\2191";
      top: 16px;
    }

    .sorting:after,
    .sorting_asc:after,
    .sorting_desc:after {
      right: 0.5em;
      content: "\2193";
      top: 16px;
    }
  }
}

table.dataTable thead .sorting_asc_disabled:after,
.transcation-crypto table.dataTable thead .sorting_desc_disabled:after {
  right: 0.5em;
  content: "\2193";
  top: 16px;
}

@media (max-width: 767px) {
  .payment-cards img {
    top: 26px !important;
  }
}

.card-order i {
  background: #edebff;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  font-size: 21px;
}

.owl-theme:before {
  content: '';
  display: block;
  left: 0;
  background: linear-gradient(to left, transparent 0%, $background 100%);
  bottom: 0px;
  position: absolute;
  width: 100px;
  height: 100%;
  z-index: 1;
}

.owl-theme:after {
  content: '';
  display: block;
  right: 0;
  background: linear-gradient(to right, transparent 0%, $background 100%);
  bottom: 0px;
  position: absolute;
  width: 100px;
  height: 100%;
  z-index: 1;
}

.crypto-btc-usd.nav-tabs .nav-link {
  padding: 10px 0px;
  line-height: 0.5;
}

.wallet-1 {
  .table-responsive .table> :not(caption)>*>* {
    border-bottom: 0 !important;
  }
}

#btc_chart {
  .apexcharts-canvas {
    width: 100% !important;
  }
}

/* ###### Cryptocurrencies  ###### */