/* ###### Rating  ###### */

.main-rating-value {
  font-size: 40px;
  font-weight: 400;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #8f9cc0;
  margin-bottom: 0;
  letter-spacing: -0.5px;
  line-height: 0.7;
}

.main-rating-label {
  margin-bottom: 10px;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.rating-stars {
  width: 100%;
  text-align: center;
  font-size: 20px;

  .rating-stars-container {
    font-size: 0px;

    .rating-star {
      display: inline-block;
      font-size: 30px;
      cursor: pointer;
      padding: 4px 8px;
      color: #f0f2f8;

      &.is--active .fa-heart,
      &.is--hover .fa-heart {
        color: #fb0d00;
      }

      &.sm {
        display: inline-block;
        font-size: 14px;
        color: #eaedf1;
        cursor: pointer;
        padding: 5px;
      }

      &.is--active,
      &.is--hover {
        color: #f1c40f;
      }

      &.is--no-hover,
      .fa-heart .is--no-hover {
        color: #f1f1f9;
      }
    }
  }
}

.rating-stars input {
  display: block;
  margin: 0 auto;
  text-align: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #495057;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid #eaedf1;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.rating-stars i {
  padding: 4px;
}

.jq-stars {
  display: inline-block;
}

.jq-rating-label {
  font-size: 22px;
  display: inline-block;
  position: relative;
  vertical-align: top;
  font-family: helvetica, arial, verdana;
}

.jq-star {
  width: 100px;
  height: 100px;
  display: inline-block;
  cursor: pointer;
}

.jq-star-svg {
  padding-left: 3px;
  width: 100%;
  height: 100%;
}

.jq-star-svg path {
  stroke-linejoin: round;
}

/* un-used */
.jq-shadow {
  -webkit-filter: drop-shadow(-2px -2px 2px #888);
  filter: drop-shadow(-2px -2px 2px #888);
}
/* ###### Rating  ###### */
