body {
  background: $background;
}

.custom-card {
  box-shadow: 0 10px 30px 0 $primary-005;
}

.bg-primary {
  background-color: $primary  !important;

  &::hover,
  &::focus {
    background-color: $primary  !important;
  }
}

.bg-primary-transparent {
  background-color: $primary-transparent;
}

.select2-container--classic {

  .select2-selection--single:focus,
  &.select2-container--open .select2-selection--single,
  .select2-selection--multiple:focus {
    border-color: $primary;
  }

  &.select2-container--open {
    .select2-selection--multiple {
      border-color: $primary;
    }

    .select2-dropdown {
      border-color: $primary;
    }
  }
}

.main-header-center .search-btn {
  background-color: $primary;
}

.text-primary {
  color: $primary  !important;

  &:hover,
  :focus {
    color: $primary  !important;
  }
}

.border-primary {
  border-color: $primary  !important;
}

.bd-primary {
  border-color: $primary;
}

.card-item .card-item-icon.card-icon {
  background: $primary-01;
  fill: $primary;
}

.card-dashboard-calendar .ui-datepicker {
  .ui-datepicker-month {
    color: $primary;
  }

  .ui-datepicker-calendar .ui-datepicker-today a {
    background-color: $primary;
    border-color: $primary;
  }
}

#back-to-top {
  background: $primary;
}

.ckbox span:after {
  background-color: $primary;
}

.btn-primary {
  color: $white;
  background-color: $primary;
  border-color: $primary;

  &:hover {
    color: $white;
    background-color: $primary-hover;
    border-color: $primary-hover;
  }

  &:focus,
  &.focus {
    background-color: $primary-hover;
    border-color: $primary-hover;
    box-shadow: 0 0 0 1px $primary-07;
  }

  &.disabled,
  &:disabled {
    color: $white;
    background-color: $primary;
    border-color: $primary;
  }

  &:not(:disabled):not(.disabled) {

    &:active,
    &.active {
      color: $white;
      background-color: $primary-08;
      border-color: $primary-08;
    }
  }
}

.show>.btn-primary.dropdown-toggle {
  color: $white;
  background-color: $primary-08;
  border-color: $primary-08;
}

.btn-primary:not(:disabled):not(.disabled) {

  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 1px $primary-07;
  }
}

.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px $primary-07;
}

.btn-main-primary {
  color: $white;
  background-color: $primary;
  border-color: $primary;

  &:hover {
    color: $white;
    background-color: $primary-hover;
    border-color: $primary-hover;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 1px $primary-07;
  }

  &.disabled,
  &:disabled {
    color: $white;
    background-color: $primary;
    border-color: $primary;
  }

  &:not(:disabled):not(.disabled) {

    &:active,
    &.active {
      color: $white;
      background-color: $primary-08;
      border-color: $primary-08;
    }
  }
}

.show>.btn-main-primary.dropdown-toggle {
  color: $white;
  background-color: $primary-08;
  border-color: $primary-08;
}

.btn-main-primary:not(:disabled):not(.disabled) {

  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 1px $primary-07;
  }
}

.show>.btn-main-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px $primary-07;
}

.btn-outline-primary {
  color: $primary  !important;
  border-color: $primary;

  &:hover {
    color: $white  !important;
    background-color: $primary;
    border-color: $primary;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 1px $primary-07;
  }

  &.disabled,
  &:disabled {
    color: $primary;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {

    &:active,
    &.active {
      color: $white  !important;
      background-color: $primary;
      border-color: $primary;
    }
  }
}

.show>.btn-outline-primary.dropdown-toggle {
  color: $white  !important;
  background-color: $primary;
  border-color: $primary;
}

.btn-outline-primary:not(:disabled):not(.disabled) {

  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 1px $primary-07;
  }
}

.show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px $primary-07;
}

.bg-primary {
  color: $white  !important;
  background-color: $primary;
}

a.bg-primary {
  &:hover {
    color: $white  !important;
    background-color: $primary;
  }

  &:focus {
    color: $white  !important;
    background-color: $primary;
    outline: 0;
    box-shadow: 0 0 0 0.2rem $primary-05;
  }

  &.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem $primary-05;
  }
}

.main-nav-column .nav-link.active {
  color: $primary  !important;

  &:hover,
  &:focus {
    color: $primary  !important;
  }
}

a {
  color: $primary;

  &:hover {
    color: $primary;
  }
}

.main-nav-line-chat .nav-link.active,
.main-msg-send {
  color: $primary  !important;
}

.main-calendar {
  .fc-view.fc-agenda-view .fc-day-header.fc-today>a {
    color: $primary;
  }

  td.fc-today .fc-day-number {
    background-color: $primary;

    &:hover,
    &:focus {
      background-color: $primary;
    }
  }
}

.main-avatar {
  background-color: $primary;
}

.page-item.active .page-link {
  background-color: $primary;
  border-color: $primary;
}

table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]> {

  td:first-child:before,
  th:first-child:before {
    background-color: $primary;
  }
}

.nav-pills .nav-link.active {
  background-color: $primary;
  color: $white  !important;
}

.main-nav .nav-link.active {
  color: $primary  !important;
}

.accordion .card-header a {
  color: $primary;
}

.accordion-color .card-header a.collapsed {
  background-color: $primary  !important;
}

.timeline-wrapper-primary {

  .timeline-panel:before,
  .timeline-badge {
    background: $primary;
  }
}

.main-content-body-profile .main-nav-line .nav-link.active {
  color: $primary;
}

.custom-switch-input:checked~.custom-switch-indicator {
  background: $primary;
}

.rdiobox input[type="radio"]:checked+span:before {
  border-color: transparent;
  background-color: $primary;
}

.selectgroup-input:checked+.selectgroup-button {
  border-color: $primary;
  color: $primary;
}

.ui-datepicker {
  .ui-datepicker-header {

    .ui-datepicker-next::before,
    .ui-datepicker-prev::before {
      background: $primary-01;
      color: $primary;
    }
  }

  .ui-datepicker-calendar .ui-datepicker-today a {
    background-color: $primary;
    color: $white;

    &:hover,
    &:focus {
      background-color: $primary;
      color: $white;
    }
  }
}

.irs-bar {
  background-color: $primary;
}

.irs-from,
.irs-to,
.irs-single {
  color: $white;
  background: $primary;
}

.irs-line-mid,
.irs-line-right {
  background-color: $background;
}

.select2-container--default {
  .select2-results__option--highlighted[aria-selected] {
    color: $primary;
  }
}

.wizard>.steps {
  .current a {

    .number,
    &:hover .number,
    &:active .number {
      background-color: $primary  !important;
    }
  }
}

.profile-cover__info .nav strong,
.profile-tab .main-nav-line .nav-link.active {
  color: $primary  !important;
}

.pricing-tabs .nav-price li .active {
  color: $primary;
}

.signpages .details:before {
  background: $primary;
}

.construction:before {
  background: $primary;
}

.tag-primary,
.progress-bar {
  background-color: $primary;
}

.breadcrumb {
  background-color: $background;
}

.bg-primary .main-nav .nav-link.active,
.bg-secondary .main-nav .nav-link.active {
  color: $white  !important;
}

.popover-header {
  background-color: $primary;
  color: $white;
}

.lds-heart div {

  &:after,
  &:before {
    background: $primary;
  }
}

.lds-ripple div {
  border-color: $primary;
}

.lds-spinner div:after {
  background: $primary;
}

.lds-circle>div {
  background: $primary;
}

.lds-dual-ring:after {
  border-color: $primary;
  border-color: $primary transparent $primary transparent;
}

.lds-facebook div {
  background: $primary;
}

.main-navbar {
  .nav-item.active .nav-link {
    color: $white;

    &.with-sub::after {
      color: $white;
    }
  }

  .nav-sub-item.active>.nav-sub-link.active {
    color: $white;

    &:before {
      color: $white;
    }

    .sub-with-sub::after {
      color: $white  !important;
    }
  }

  .nav-sub-link {

    &:hover,
    &:focus {
      color: $white  !important;
    }
  }
}

@media (min-width: 992px) {
  .main-navbar {
    .nav-sub-link {

      &:hover,
      &:focus {
        color: $white;
      }
    }

    .nav-sub-item.active>.nav-sub-link.active {
      color: $white;

      &:before {
        color: $white;
      }
    }
  }
}

@media (max-width: 991px) {
  .main-navbar {

    .nav-sub-item.active>.nav-sub-link.active {
      color: $white;

      &:before {
        color: $white;
      }
    }
  }

  .main-navbar .nav-sub-link:hover,
  .main-navbar .nav-sub-link:focus {
    color: $white  !important;
  }
}

.main-form-search {

  .btn,
  .sp-container button {
    background-color: $primary;
  }
}

.flat a {

  &:hover,
  &.active,
  &:hover:after,
  &.active:after {
    background: $primary  !important;
    color: $white  !important;
  }
}

.nav-tabs .nav-link {

  &:hover,
  &:focus {
    background-color: $primary;
    color: $white  !important;
  }
}

.lds-heart div {
  background: $primary;
}

.accordion .card-header a.collapsed {

  &:hover,
  &:focus {
    background-color: $primary;
    color: $white;
  }
}

.main-sidebar-body li.active {

  .sidemenu-label,
  i {
    color: $primary;
  }
}

.main-sidebar-body li.active {
  li.active .nav-sub-link.sub-with-sub {
    color: $white  !important;

    .sidemenu-label,
    i,
    &::before {
      color: $white  !important;
      opacity: 1 !important;
    }
  }
}

.main-sidebar-body li.nav-sub-item.active .nav-sub-item .nav-sub-link.active {
  color: $white  !important;
}

.main-sidebar-body li.nav-sub-item.active .nav-sub-item .nav-sub-link.active::before {
  color: $white  !important;
  opacity: 1 !important;
}

.main-sidebar-body {
  .nav-sub-link {

    &:hover,
    &:focus {
      color: $white  !important;

      &::before {
        color: $white  !important;
        opacity: 1 !important;
      }

      &.sub-with-sub {

        .sidemenu-label,
        .angle {
          color: $white  !important;
        }
      }
    }
  }

  li.active .sidemenu-icon {
    background: $primary;
  }

  .nav-sub-item.active .nav-sub-item .nav-sub-link {

    &:hover,
    &:focus {
      color: $white  !important;

      &::before {
        color: $white  !important;
      }
    }
  }
}

.project-status.primary:before {
  border-left-color: $primary;
  border-right-color: $primary;
}

.bg-primary-light {
  background-color: $primary-transparent;
  color: $primary;
}

.list-card {
  background: $primary-005;
}

.forth.circle .chart-circle-value.circle-style {
  border-color: $primary-07;
}

.crypto-btc-usd .nav-link.active {
  background-color: $primary  !important;
  color: $white;
}

.irs-slider:before {
  background-color: $primary  !important;
}

.custom-control-input:checked~.custom-control-label::before {
  color: $white;
  border-color: $primary;
  background-color: $primary;
}

.sweet-alert button {
  background-color: $primary  !important;
}

.popover-primary {
  background-color: $primary  !important;
  border-color: $primary;
}

.popover-primary {

  &.bs-popover-top>.arrow::after,
  &.bs-popover-auto[data-popper-placement^="top"]>.arrow::after,
  &.bs-popover-top>.arrow::before,
  &.bs-popover-auto[data-popper-placement^="top"]>.arrow::before {
    border-top-color: $primary;
  }
}

.peity {
  path:first-child {
    fill: $primary;
  }

  path:last-child {
    fill: $primary-01;
  }
}

.main-navbar .nav-sub-link.active.sub-with-sub::after {
  color: $white  !important;
}

@media (min-width: 992px) {

  .main-navbar .nav-sub-link:hover.sub-with-sub::after,
  .main-navbar .nav-sub-link:focus.sub-with-sub::after {
    color: $white  !important;
  }
}

.lds-dual-ring:after {
  border: 5px solid $primary;
  border-color: $primary transparent $primary transparent;
}

.lds-ripple div {
  border: 4px solid $primary;
}

.alert-message {
  border: 1px solid $primary  !important;
  border-radius: 3px;
  background: $black-1  !important;
  padding: 1rem;
}

.main-sidebar-body .nav-sub-item.active>.nav-sub-link.active:before {
  color: $white !important;
  opacity: 1;
}