/* ###### Custom-styles ###### */

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  &::before {
    display: block;
    content: "";
  }

  .embed-responsive-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

@media print {
  * {
    text-shadow: none !important;
    box-shadow: none !important;

    &::before,
    &::after {
      text-shadow: none !important;
      box-shadow: none !important;
    }
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " ('attr(title) ')";
  }

  pre {
    white-space: pre-wrap !important;
    border: 1px solid #a8afc7;
    page-break-inside: avoid;
  }

  blockquote {
    border: 1px solid #a8afc7;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body,
  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid $black;
  }

  .table {
    border-collapse: collapse !important;

    td,
    th {
      background-color: $white  !important;
    }
  }

  .table-bordered {

    th,
    td {
      border: 1px solid $border  !important;
    }
  }

  .table-dark {
    color: inherit;

    th,
    td,
    thead th,
    tbody+tbody {
      border-color: $background;
    }
  }

  .table .thead-dark th {
    color: inherit;
    border-color: $background;
  }
}

.main-body,
.main-dashboard {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.main-body::before {
  content: "main";
  position: fixed;
  top: 45%;
  left: 70%;
  transform: translate3d(-50%, -58%, 0);
  font-size: 1300px;
  font-weight: 600;
  letter-spacing: -10px;
  line-height: 0.5;
  opacity: 0.02;
  z-index: -1;
  display: none;
}

.main-table-reference {
  margin-top: 40px;
  background-color: $white;

  > {
    thead>tr> {

      th,
      td {
        padding: 8px 10px;
        border: 1px solid $border;
        font-size: 13px;
        background-color: $background;
        color: #4b4f56;
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
        border-bottom: 0;
      }
    }

    tbody>tr> {

      th,
      td {
        padding: 8px 10px;
        border: 1px solid $border;
        font-size: 13px;
      }
    }
  }
}

.main-notification-title {
  font-size: 18px;
  font-weight: 600;
  color: #11141f;
  margin-bottom: 5px;
  line-height: 1;
}

.main-notification-text {
  font-size: 13px;
  margin-bottom: 0px;
  color: #3c4858;
}

.main-notification-list,
.main-profile-menu .dropdown-menu .dropdown-item:first-child {
  border-top: 1px solid $border;
}

.main-notification-list {
  .media {
    padding: 10px 10px;
    position: relative;

    +.media {
      border-top: 1px solid rgba(123, 65, 216, 0.1);
    }

    &.new {
      color: #8f9cc0;
    }

    &:hover,
    &:focus {
      cursor: pointer;
      z-index: 1;
    }

    &:hover::before,
    &:focus::before {
      content: "";
      position: absolute;
      top: -1px;
      left: 0px;
      bottom: -1px;
      right: 0px;
      background-color: $light;
      border-top: 1px solid $border;
      border-bottom: 1px solid $border;
      z-index: -1;
    }
  }

  .media-body {
    margin-left: 15px;
    font-size: 13px;

    p {
      margin-bottom: 5px;
      line-height: 1.3;
      color: #444469;
    }

    strong {
      font-weight: 500;
    }

    span {
      display: block;
      font-size: 11px;
      color: #a8afc7;
    }
  }
}

.main-toggle-group-demo {
  display: flex;

  .main-toggle+.main-toggle {
    margin-left: 10px;
  }
}

.main-dropdown-form-demo {
  .static-dropdown {
    padding: 20px;
    background-color: $background;
    display: inline-flex;
    justify-content: center;

    .dropdown-menu {
      display: block;
      position: static;
      float: none;
    }
  }

  .dropdown-menu {
    padding: 20px;
    box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
  }

  .dropdown-title {
    font-size: 20px;
    font-weight: 700;
    color: #565673;
    margin-bottom: 5px;
  }
}

@media (min-width: 576px) {
  .main-dropdown-form-demo .static-dropdown {
    padding: 40px;
  }
}

@media (min-width: 576px) {
  .main-dropdown-form-demo .dropdown-menu {
    width: 300px;
    padding: 30px 25px;
  }
}

.main-content-left-components {
  border-right: 1px solid $border;
  margin-bottom: 20px;

  .component-item {
    label {
      display: block;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.5px;

      ~label {
        margin-top: 30px;
      }
    }

    .nav-link {
      padding: 0;
      display: block;
      font-size: 13px;
      color: #3c4858;

      +.nav-link {
        margin-top: 5px;
      }

      &.active {
        font-weight: 500;
      }
    }
  }
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.main-body #chartLine {
  height: 275px !important;
}

.legend {
  width: 8px;
  height: 8px;
  display: block;
  border-radius: 2px;
  margin-right: 10px;
  margin-top: 6px;
}

.fullscreen {
  .fullscreen {
    display: none;
  }

  .exit-fullscreen {
    display: block !important;
  }
}

.exit-fullscreen {
  display: none;
}

.best-emp {
  position: absolute;
  right: 0;
  top: -15px;
  height: 135px;
}

.flag-dropdown {
  .dropdown-menu {
    width: 150px;
    padding: 8px 0;

    img {
      min-width: 0;
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      line-height: 24px;
      border-radius: 50%;
    }
  }

  .dropdown-item {
    padding: 3px 10px;
    font-size: 13px;
    color: #2e2e4a;
    font-weight: 500;
  }
}

.userlist-table {
  img {
    max-width: inherit;
  }

  .dot-label {
    margin-top: 5px;
    margin-right: 10px !important;
  }
}

@media (max-width: 768px) {
  .text-md-nowrap {
    white-space: nowrap !important;
  }

  .nav.main-nav-line.main-nav-line-chat.card-body {
    display: flex;
  }

  .nav.main-nav-line.main-nav-line-chat.card-body a {
    display: flex;
    padding: 0 8px;
  }

  .nav.main-nav-line.main-nav-line-chat.card-body .nav-link+.nav-link {
    margin-top: 0;
    margin-left: 0;
  }
}

@media (max-width: 576px) {
  .page-header {
    display: block !important;
  }

  .project-work.list-unstyled li {
    margin-top: 1rem;
    margin-right: 1rem;
  }

  .main-header-notification.show .dropdown-menu::before,
  .main-header-message.show .dropdown-menu::before,
  .main-profile-menu.show .dropdown-menu::before {
    display: none;
  }
}

.main-header-right .dropdown-menu .select2-container--default .select2-selection--single {
  background-color: $white;
  border: 1px solid $border;
  border-radius: 3px 0 0 3px;
  border-right: 0;
}

@media (min-width: 992px) {
  .navbar-toggler.navresponsive-toggler {
    display: none;
  }
}

@media (max-width: 991px) {

  .main-body .mobile-logo-dark,
  .horizontalmenu .mobile-logo-dark {
    display: none;
  }

  .main-header.side-header {
    border-bottom: 1px solid #ddddf7;
  }

  .sidemenu-logo .main-logo {
    display: none;
  }

  .main-header {
    z-index: 9999;
  }

  .header-search .select2-container--default .select2-selection--single {
    background-color: $white;
    border: 1px solid $border;
    border-radius: 3px 0 0 3px;
    border-right: 0;
    height: 40px;
  }

  .sidebar {
    top: 60px !important;
  }

  .horizontalmenu .header-brand-img.desktop-logo {
    display: none;
  }

  .responsive-navbar.navbar {
    position: relative !important;
    display: -ms-flexbox;
    display: flex;
    top: 65px;
    margin: 0;
    margin-bottom: 0 !important;
    padding: 0;

    .navbar-collapse {
      padding: 5px;
      position: fixed;
      width: 100%;
      background: $white;
      margin-top: 0;
      z-index: 999;
      top: 59px;
      border-bottom: 1px solid $white;
      border-top: 1px solid $background;
      padding-left: 1rem;
      padding-right: 0.3rem;
      box-shadow: 7px 8px 9px -2px #c5c5d8;
      left: 0;
      right: 0;
    }
  }
}

@media (max-width: 991px) {
  .main-header-center {

    margin: 0;
    position: relative;
    margin: 0 auto;
    text-align: center;
  }

  .responsive-navbar .dropdown {
    position: initial;
    margin: auto 0;
  }

  #navbarSupportedContent-4 .dropdown-menu {
    width: 93% !important;
    left: 0 !important;
    right: 0 !important;
    margin: 0 20px;
    justify-content: center;
  }

  #navbarSupportedContent-4 {

    .main-header-notification.show .dropdown-menu::before,
    .main-header-message.show .dropdown-menu::before,
    .main-profile-menu.show .dropdown-menu::before {
      display: none;
    }
  }

  .main-header-notification .dropdown-menu,
  .main-header-message .dropdown-menu {
    top: 51px !important;
  }

  #navbarSupportedContent-4 .nav-link {
    &.icon {
      font-size: 18px;
    }
  }

  .sidemenu-logo,
  .main-calendar .fc-header-toolbar {
    padding: 0 !important;
  }

  .mapcontainer.mapael svg,
  .mapcontainer1.mapael svg,
  .mapcontainer2 svg,
  .mapcontainer3 svg {
    width: 250px !important;
  }

  .mapcontainer .map,
  .mapcontainer1 .map {
    height: 300px !important;
  }

  .breadcrumbitem {
    display: none;
  }

  .intl-tel-input input {
    width: 156px !important;
  }

  .horizontalmenu {
    .mobile-logo-dark {
      display: none;
    }
  }

  .sidemenu-logo {
    display: none;
  }
}

.media img {
  border-radius: 4px;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.media-logo,
.media-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  color: $white;
  font-size: 21px;
  position: relative;
  top: 2px;
  border-radius: 100%;
}

.flat a {
  background: white;
  color: black;
  transition: all 0.5s;

  &:after {
    background: white;
    color: black;
    transition: all 0.5s;
  }

  &.active:before,
  &:hover:before {
    color: $black;
  }

  &:before {
    background: white;
    box-shadow: 0 0 0 1px #ccc;
  }
}

@media (max-width: 768px) {
  .nav.main-nav-line {
    display: block;
  }
}

.user-lock .option-dots {
  margin: inherit !important;
  width: inherit !important;
  height: inherit !important;
  border-radius: inherit !important;
  background: transparent;
  text-align: inherit !important;
  line-height: inherit !important;
  display: initial !important;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  border-radius: 10px;
}

.box-shadow {
  box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
}

.box-shadow-0 {
  box-shadow: none !important;
}

.cover-image {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover !important;
}

.page-header {
  .breadcrumb {
    background: none;
    padding-left: 0 !important;
    padding: 0;
    margin-bottom: 0;
  }

  .breadcrumb-item {
    a {
      color: #8f9cc0;
    }

    &.active {
      color: #7787bc;
    }
  }

  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin: 1.5rem 0 1.5rem;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  position: relative;
  min-height: 50px;
  margin: 1.8rem 0 1.5rem 0;
  border-radius: 7px;
}

@media (max-width: 991px) {
  .main-navbar-show .responsive-navbar.navbar .navbar-collapse {
    z-index: 9999;
  }

  .main-navbar-show .sidebar {
    z-index: 99999;
  }

  .header-search .select2-container--default .select2-selection--single .select2-selection__arrow b {
    left: 93% !important;
  }

  .jumps-prevent {
    padding-top: 0 !important;
  }

  .main-navbar.sticky.sticky-pin {
    margin-bottom: 0 !important;
  }

  .page-header {
    display: block;

    .breadcrumb {
      margin-bottom: 1rem;
    }
  }

  #mailContent {
    .main-mail-list {
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }

    .main-mail-item {
      -webkit-overflow-scrolling: touch;
    }
  }

  .main-chat-footer {
    position: inherit;
  }

  .main-header-notification>a::after,
  .main-header-message>a::after {
    top: 40px;
  }

  .main-header-project {
    .dropdown-menu {
      top: 46px;
    }

    >a::after {
      top: 40px;
    }
  }

  .main-profile-menu {
    .dropdown-menu {
      top: 51px;
    }

    >.main-img-user::before {
      bottom: -20px;
    }
  }

  .main-header {
    position: fixed;
    box-shadow: none;
    border-bottom: 1px solid #f0f2f8;
    border-top: 0;
  }

  .main-logo .header-brand-img {
    margin-right: 10px;
  }

  .side-header {
    position: fixed !important;
  }

  .main-sidebar-hide {
    .side-header {
      left: 0px;
      right: 0px;
    }

    .sidemenu-logo {
      padding: 5px;
    }
  }

  .main-content .page-header .btn.btn-list {
    padding-left: 0;
    text-align: left;
  }

  #example-input.dataTable>tbody>tr.child ul.dtr-details>li {
    display: flex !important;
  }
}

@media (max-width: 767px) {
  .main-content-body-contacts {
    border-top: 1px solid #f0f2f8;
  }

  div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0 !important;
  }

  div.table-responsive>div.dataTables_wrapper>div.row>div[class^="col-"]:last-child {
    padding-right: 0;
    padding: 0;
  }

  .card-option {
    display: inline-block !important;
  }

  .card-option-title {
    margin-bottom: 10px;
  }

  .header-search {
    &.dropdown {
      position: initial !important;
    }

    .dropdown-menu {
      position: absolute !important;
      left: -1px !important;
      right: -1px !important;
      width: 100% !important;
      top: 100% !important;
    }
  }

  .main-content {

    &.side-content .page-header,
    .page-header {
      padding-top: 63px !important;
    }
  }

  .header-search.show .dropdown-menu::before {
    display: none;
  }

  .timeline .btn {
    font-size: 12px;
  }

  .vtimeline {
    &:before {
      left: 6px !important;
    }

    .timeline-wrapper {
      .timeline-badge {
        left: 0 !important;
      }

      padding-right: 0 !important;

      .timeline-panel {
        width: auto !important;
        margin-left: 10% !important;

        &:after {
          border-right: 14px solid #cad4e4 !important;
          border-left: 0 solid #cad4e4 !important;
          left: -14px !important;
          right: auto !important;
        }
      }

      &.timeline-inverted {
        padding-left: 0 !important;

        .timeline-panel {
          margin-right: 0 !important;
          width: auto !important;
        }
      }
    }
  }
}

@media (max-width: 380px) {
  .main-header-message {
    display: none;
  }

  .carousel-slider #thumbcarousel .carousel-item .thumb {
    margin: 5px !important;
  }

  .main-nav-line-chat .nav-link {
    font-size: 12px;
  }

  .main-mail-header>div:last-child {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .main-logo .header-brand-img {
    &.desktop-logo {
      display: none;
    }

    &.icon-logo {
      display: block;
    }
  }

  .flot-chart1 {
    height: 200px !important;
  }

  .main-contact-info-header .media-body .nav-link {
    font-size: 12px;
  }

  .modal.pos-static .modal-footer {
    display: block;

    .btn {
      width: 100%;
    }

    > :not(:first-child) {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}

@media (min-width: 992px) {
  .sticky {
    width: 100%;
  }

  .horizontalmenu .sticky-pin {
    position: fixed !important;
    top: 0 !important;
    width: 100%;
    z-index: 99;
  }

  .main-navbar.sticky.sticky-pin {
    border-bottom: 1px solid #f0f2f8;
  }

  .main-content {
    padding-top: 58px;
    padding-top: 58px;

    &.side-content {
      padding-top: 63px;
    }
  }

  .main-content-app,
  .main-content-calEndar,
  .main-content-contacts {
    display: flex;
  }

  .main-error-wrapper h1 {
    font-size: 12rem;
  }

  .horizontalmenu .main-footer {
    padding-left: 0;
  }
}

.flag-icon {
  font-size: 24px;
  line-height: 1;
  border-radius: 100%;
}

#back-to-top {
  color: $white;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  display: none;
  text-align: center;
  z-index: 10000;
  height: 32px;
  width: 32px;
  line-height: 15px;
  background-repeat: no-repeat;
  border: 2px solid transparent;
  background-position: center;
  transition: background-color 0.1s linear;
  -moz-transition: background-color 0.1s linear;
  -webkit-transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;
  border-radius: 3px;

  i {
    padding-top: 6px;
    font-size: 14px;
    line-height: 1.8rem;
  }

  &:hover {
    background: $white  !important;
    color: $primary  !important;
  }
}

.contact-icon {
  display: inline-flex;
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &:hover {
    background: #f0f2f8;
  }
}

.custom-leftnav .main-content-left-components {
  position: -webkit-sticky;
  position: sticky;
  border-right: 0;
  top: 4rem;
}

.component-item a:before {
  content: "\e92f";
  margin-right: 8px;
  position: relative;
  font-family: "feather" !important;
  color: #68798b;
}

.main-icon-list {
  padding: 10px;
  border: 1px solid #f0f2f8;
}

.description-block {
  display: block;
  margin: 10px 0;
  text-align: center;
}

.skill-tags a {
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
  border-radius: 0px;
  color: #a8afc7;
  font-size: 12px;
  border: 1px solid #f0f2f8;
}

.reviewnavs {
  padding-left: 0;
  list-style: none;
  display: inline-flex;

  li {
    margin-right: 5px;

    a {
      color: #a8afc7;
    }
  }
}

.dash-icon {
  position: absolute;
  right: 10px;
  font-size: 3rem;
  opacity: 0.5;
  color: #d3d9e4;
  top: 10px;
}

.main-traffic-detail-product-item {
  margin-bottom: 1.5rem;
}

.price-img {
  padding-top: 2rem;

  i {
    font-size: 3rem;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    line-height: 100px;
  }
}

.price-1 .list-unstyled li {
  padding: 7px 0;
}

.card-pricing h1 {
  font-size: 2.5rem !important;
  padding: 15px 30px;
  text-align: center;
}

.pricing-title {
  text-align: center;
  padding-top: 20px;
  font-size: 20px;
  font-weight: 500;
}

.card-pricing2 h1,
.card-pricing3 h1,
.card-pricing4 h1 {
  font-size: 2.5rem !important;
  padding: 15px 30px;
  text-align: center;
}

.card-pricing .list-unstyled li,
.card-pricing2 .list-unstyled li,
.card-pricing3 .list-unstyled li,
.card-pricing4 .list-unstyled li {
  padding: 0.5rem 0;
  border-bottom: 0;
  display: block;
}

.pricing-tabs {
  .pri-tabs-heading {
    padding: 15px;
    text-align: center;
    margin: 0 auto;
    display: inline-table;
  }

  .nav-price li {
    a {
      padding: 10px 20px 10px 20px;
      color: #828db1;
      background: $white;
      border: 1px solid #f0f2f8;
    }

    .active {
      background: #ececfc;
    }
  }

  .tab-content {
    padding: 15px;
    margin-top: 1.5rem;
  }
}

.pricing1 .price {
  font-size: 2.5rem;
}

.pricingTable2 {
  text-align: center;
  background: $white;
  border: 1px solid #e0e5f3;
}

.pricingTable2-header {
  padding: 25px 10px;
  background-color: $white;
  border-radius: 6px !important;
}

.pricingTable2 .pricingTable2-header h3 {
  font-size: 21px;
  letter-spacing: 2px;
  color: $black;
  text-transform: uppercase;
  margin: 0px;
}

.pricingTable2-header span {
  display: none;
  font-size: 12px;
}

.card-pricing.pricing1 .list-unstyled li {
  display: block;
}

.pricing-plans {
  padding: 25px 0;
}

.price-value1 {
  font-size: 25px;
  line-height: 35px;
  color: $white;
  display: inline-block;
  position: relative;

  i {
    position: absolute;
    top: 3px;
    font-size: 22px;
  }

  span {
    font-size: 40px;
    color: $white;
    margin-left: 17px;
  }
}

.pricing-plans .month {
  color: $white;
  display: inline-block;
  font-size: 15px;
  margin-top: 15px;
  font-weight: 100;
}

.pricingContent2 ul {
  padding: 0 15px;
  margin-bottom: 0;
  list-style: none;

  li {
    padding: 25px 0 0 0;
    text-transform: capitalize;

    &:last-child {
      padding-bottom: 25px;
    }
  }
}

.price-value-content ul li {
  padding: 8px 0;
  text-transform: capitalize;
}

.pricingTable2 {
  .pricingTable2-sign-up {
    padding: 25px 0;
    border-top: 1px solid #e0e5f3;
  }

  .btn-block {
    width: 50%;
    margin: 0 auto;
    padding: 10px 0;
    color: $white;
    text-transform: uppercase;
    border-radius: 50px;
    transition: 0.3s ease;
  }

  &.green .pricing-plans {
    background-color: #10d403;
  }

  &.info .pricing-plans {
    background-color: #0ab2e6;
  }
}

@media screen and (max-width: 990px) {
  .pricingTable2 {
    margin-bottom: 30px;
  }
}

.example {
  padding: 1rem;
  border: 1px solid #f0f2f8;
  font-size: 0.9375rem;

  +.highlight {
    border-top: none;
    margin-top: 0;
  }
}

.text-wrap> :last-child {
  margin-bottom: 0;
}

.highlight {
  margin: 1rem 0 2rem;
  border: 1px solid #f0f2f8;
  border-radius: 0px;
  font-size: 0.9375rem;
  max-height: 20rem;
  background: #edeff7;
}

.clip-widget {
  position: relative;
  overflow-y: scroll;
}

.clipboard-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 14px;
  background: $white;
  border: 1px solid #f0f2f8;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.user-card .main-img-user {
  margin: 0 auto;
}

.icon-service {
  display: inline-flex;
  width: 3.5rem;
  height: 3.5rem;
  padding: 1.1rem;
  margin-bottom: 0.5rem;

  i {
    font-size: 1.2rem;
  }
}

.our-team {
  .picture {
    height: 100px;
    width: 100px;
    margin: 0 auto;
    z-index: 0;
    position: relative;
    overflow: hidden;
  }

  &:hover .picture::before {
    height: 100%;
  }

  .picture {
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    img {
      width: 100%;
      height: auto;
      border-radius: 50%;
      transform: scale(1);
      transition: all 0.9s ease 0s;
    }
  }

  &:hover .picture img {
    box-shadow: 0 0 0 3px #f0f2f8;
    transform: scale(0.8);
  }
}

.iti__hide {
  display: none;
}

.token {

  &.operator,
  &.entity,
  &.url {
    background: none;
  }
}

.language-css .token.string,
.style .token.string,
.token.variable {
  background: none;
}

.user-lock img {
  width: 70px;
  height: 70px;
  margin: 0 auto;
}

.eamil-body {
  margin-top: 30px;
}

.task-list {
  list-style: none;
  position: relative;
  margin: 0;
  padding: 30px 0 0;
  color: #3c4858;

  &:after {
    content: "";
    position: absolute;
    background: #f0f2f8;
    height: 100%;
    width: 1px;
    top: 0;
    left: 30px;
    z-index: 1;
  }

  li {
    margin-bottom: 30px;
    padding-left: 55px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    .task-icon {
      position: absolute;
      left: 22px;
      top: 13px;
      border-radius: 50%;
      padding: 2px;
      width: 17px;
      height: 17px;
      z-index: 2;
    }

    h6 {
      margin-bottom: 5px;
    }
  }
}

.item-card {
  .product-info {
    padding: 0;
    margin: 0;
    opacity: 0;
    left: 0;
    right: 0;
    text-align: center;
    position: absolute;
    bottom: -30px;
    z-index: 1;
    transition: all 0.5s ease 0s;
  }

  &:hover .product-info {
    opacity: 1;
    bottom: 30%;
  }
}

.acc-step-number {
  padding: 5px 10px;
  font-size: 14px;
}

.dt-button-collection .dropdown-item {

  &.active,
  &:active {
    color: #8f9cc0;
    text-decoration: none;
    background-color: $white;
  }
}

.advanced-search {
  background: $white;
  padding: 1.25rem;
  box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
  margin-bottom: 20px;
}

.theme-logos {
  display: none;
}

.dot-label {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  margin-right: 5px;
}

.contact-info .contact-icon:hover {
  color: $black;
}

.scroll-widget {
  width: 100%;
  height: 385px;
  overflow: hidden;
}

.activity-block {
  .task-list {
    li {
      padding-left: 3rem;
      margin-bottom: 20px;
    }

    padding-top: 0;
  }

  h6 {
    margin-bottom: 0px;
  }

  .task-list {
    &:after {
      left: 18px;
      z-index: 0;
    }

    li .task-icon {
      top: 0;
      left: 1px;
      width: 35px;
      height: 35px;
      text-align: center;
      position: absolute;
      line-height: 32px;
      font-size: 15px;
      color: $white;
    }
  }
}

.z-index2 {
  z-index: 2;
}

#global-loader {
  position: fixed;
  z-index: 50000;
  background: $white;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  display: block;
}

.loader-img {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 43%;
  left: 0;
  margin: 0 auto;
  text-align: center;
}

.user-info {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0px;
}

.setting-header {
  font-weight: 400;
  font-size: 15px;
}

.sales-icon {
  width: 3rem;
  height: 3rem;
  border-radius: 5px;
  line-height: 1.5;
  text-align: center;
  font-size: 22px;
  padding: 0.75rem;
  margin: auto 0;
}

.gift-tittle {
  font-size: 20px;
}

.gift-box span {
  font-size: 15px;
  font-weight: 500;
}

.giftbox-img {
  width: 40%;
}

.page-title {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.page-header .page-title i {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  position: relative;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  margin-right: 8px !important;
  border-radius: 7px;
  background: linear-gradient(89.1deg, rgb(240, 244, 255) 0.7%, rgb(250, 251, 254) 88.4%);
  color: #577ff5;
}

.feature {
  width: 50px;
  border-radius: 5px;

  .feature-icon {
    display: flex;
    width: 50px;
    height: 50px;
    line-height: 2em;
    vertical-align: middle;
    padding-top: 0px;
    color: $white;
    font-size: 18px;
    border-radius: 8px;
    position: relative;
    align-items: center;
    text-align: center;
    left: 0;
    right: 0;
    justify-content: center;
  }
}

.feature.bg-transparent {
  background: #fafbfe !important;
}

.browser-stats i {
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 50%;
  line-height: 30px;
  position: inherit;
  font-size: 13px;
}

.search-imgs li {
  margin-right: 1rem;
  display: contents;

  img {
    margin-right: 1rem;
    border: 1px solid #d5dae8 !important;
  }
}

.handle-counter {
  overflow: hidden;
  display: flex;

  .counter-minus,
  .counter-plus {
    float: left;
    text-align: center;
  }

  input {
    float: left;
    text-align: center;
    height: 40px;
    border-radius: 0;
    width: 104px;
    border: 1px solid #e0e6f0;
  }

  .btn {
    padding: 9px 14px !important;
  }
}

.counter-minus {
  &.btn {
    padding: 9px 15px !important;
  }

  border-radius: 3px 0 0 3px;
}

.counter-plus {
  border-radius: 0 3px 3px 0;
}

.handle-counter .btn:disabled {
  background-color: #7a7aaf;
  cursor: not-allowed;
  color: $white;

  &:hover {
    background-color: #7a869c;
    cursor: not-allowed;
  }
}

.whishlist {
  height: 20px;
  width: 20px;
}

.trash {
  height: 20px;
  width: 20px;
  fill: $danger  !important;
}

.itemside {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;

  .info {
    padding-left: 15px;
    padding-right: 7px;
  }

  .title {
    font-size: 0.9rem;
    font-weight: 500;
    color: #283250;
  }
}

.dlist-align {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.file-browser .btn-primary {
  border-radius: 0 6px 6px 0 !important;
}

.horizontalmenu .main-header {
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.03);
}

.horizontalmenu #recentorders {
  height: 222px !important;
}

.content-height {
  height: 70vh;
}

.project .deadline {
  margin-bottom: 0;
  padding: 5px 10px;
  border-radius: 4px;
}

.project-status {
  &:before {
    content: "";
    margin-right: 12px;
  }

  &.success:before {
    border-left: 3px solid $success;
  }

  &.danger:before {
    border-left: 3px solid #f16d75;
  }

  &.warning:before {
    border-left: 3px solid #ecb529;
  }
}

.list-card {
  padding: 14px 18px;
  border-radius: 11px;
  margin-bottom: 20px;
  position: relative;
}

.forth.circle {
  .chart-circle-value.circle-style {
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column;
    line-height: 1;
    width: 70px;
    height: 70px;
    line-height: 70px;
    border-radius: 50%;
  }

  canvas {
    margin: 0 auto;
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
}

.list-card {
  &.danger {
    background: #fff3f4;
  }

  &.success {
    background: #e8f7ee;
  }
}

.tasks .ckbox span {

  &:before,
  &:after {
    content: "";
    width: 18px;
    height: 18px;
    border-radius: 50%;
  }
}

.transcations.table {

  th,
  td {
    padding: 14px 0;
    line-height: 1.462;
  }

  &.table-hover tbody tr:hover {
    background-color: transparent;
  }
}

.tasks .table {

  th,
  td {
    padding: 11px 15px;
  }
}

@media (max-width: 991px) and (min-width: 767px) {
  .main-body.leftmenu .main-content.side-content {
    margin-top: 60px;
  }
}

@media (max-width: 599px) {
  .horizontalmenu .main-header-center {
    margin: 0 !important;
  }
}

.upgrade.custom-card {
  background: #f3f2fd;
}

.sidemenu-logo img {
  margin: 0 auto;
}

@media (max-width: 699px) {
  .horizontalmenu .page-header {
    display: block;
  }

  .breadcrumb-5 a:first-child {
    padding-left: 16px !important;
  }
}

.user-lock .option-dots:hover {
  background: transparent;
}

@media (min-width: 1173px) {
  .main-header.hor-header.top-header .form-control {
    width: auto !important;
  }

  .horizontalmenu .main-header.hor-header.top-header .main-header-center {
    margin: auto !important;
  }
}

@media (min-width: 992px) {
  .hor-header.top-header .header-brand2 .top-header-logo1 {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 13px;
  }

  .hor-header.top-header .header-brand {
    display: none;
  }
}

@media (max-width: 767px) {
  #checkoutsteps>.actions a {
    padding: 7px 12px;
  }
}

@media (max-width: 480px) {
  #group .pd-x-25 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .pagination-circle li a,
  .pagination-circle li span {
    height: 26px;
    width: 26px;
    min-width: 0;
  }

  .pagination-circled .page-link {
    width: 35px;
    height: 35px;
  }

  .page-link {
    padding: 5px;
  }

  .pagination-radius li a,
  .pagination-radius li span {
    width: 30px;
    height: 30px;
    min-width: 0;
  }
}

@media (max-width: 991px) {
  #inptags .form-group {
    margin-bottom: 0rem;
  }

  .bootstrap-tagsinput input {
    display: none;
  }

  .responsive-logo {
    a img {
      margin: 0 auto;
    }
  }
}

*::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  transition: 0.3s background;
}

::-webkit-scrollbar-thumb {
  background: rgb(232, 232, 247);
}

/* ###### Custom-styles ###### */

/* ###### Select Group ###### */

.selectgroup {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.selectgroup-item {
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;

  +.selectgroup-item {
    margin-left: -1px;
  }

  &:not(:first-child) .selectgroup-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:not(:last-child) .selectgroup-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.selectgroup-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}

.selectgroup-button {
  display: block;
  border: 1px solid #f0f2f8;
  text-align: center;
  padding: 0.375rem 1rem;
  position: relative;
  cursor: pointer;
  border-radius: 3px;
  color: #686868;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectgroup-button-icon {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 1rem;
}

.selectgroup-input {
  &:checked+.selectgroup-button {
    z-index: 1;
    background: #e5e9f3;
  }

  &:focus+.selectgroup-button {
    z-index: 2;
    box-shadow: none;
  }
}

.selectgroup-pills {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: start;
  align-items: flex-start;

  .selectgroup-item {
    margin-right: 0.5rem;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}

/* ###### Select Group ###### */

/* ###### Colorinput ###### */

.colorinput {
  margin-right: 5px;
  position: relative;
  cursor: pointer;
}

.colorinput-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.colorinput-color {
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  border: 1px solid transparent;
  color: $white;
  box-shadow: none;

  &:before {
    content: "";
    opacity: 0;
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    height: 1.25rem;
    width: 1.25rem;
    transition: 0.3s opacity;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center (center / 50%) 50%;
  }
}

.colorinput-input:checked~.colorinput-color:before {
  opacity: 1;
}

/* ###### Colorinput ###### */

/* ###### ie css ###### */

*::-ms-backdrop,
.b-img {
  height: 197px !important;
}

@media (min-width: 1200px) {

  *::-ms-backdrop,
  .main-navbar .nav-sub-mega .nav {
    min-width: 0 !important;
  }
}

*::-ms-backdrop,
.d-sm-flex.media.media-list {
  display: initial;
}

*::-ms-backdrop,
.t-img {
  height: 197px !important;
}

*::-ms-backdrop,
.draggable-img,
*::-ms-backdrop,
.blog-img {
  height: 258px !important;
}

*::-ms-backdrop,
.main-error-wrapper {
  align-items: inherit !important;
}

*::-ms-backdrop,
.search-imgs {
  display: -ms-inline-flexbox;
}

*::-ms-backdrop,
.main-content-body-profile .media-body {
  flex: inherit;
}

*::-ms-backdrop,
.main-msg-wrapper {
  display: inline-table;
}

*::-ms-backdrop,
.flex-wrap.ht-50.mb-lg-4 {
  height: inherit;
  margin-bottom: 0;
}

*::-ms-backdrop,
.btn-icon {
  width: inherit;
  height: inherit;
}

*::-ms-backdrop,
.bg-img:before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  z-index: -1;
  top: 0;
}

*::-ms-backdrop,
.crypto-transcation .media-body {
  flex: inherit;
}

*::-ms-backdrop,
.sales-product-info #chart {
  min-height: 219px !important;
}

*::-ms-backdrop,
.main-footer {
  width: 100%;
  position: relative;
  bottom: -5px;
  margin-top: 1.5rem;
  margin-bottom: -10px;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
}

*::-ms-backdrop,
.main-content {
  height: 100%;
}

*::-ms-backdrop,
#checkoutsteps .item .thumb {
  display: inline;
}

*::-ms-backdrop,
.transcation-crypto .wd-30 {
  width: inherit;
}

*::-ms-backdrop,
.breadcrumb-4 ol {
  justify-content: flex-end;
  display: flex;
}

*::-ms-backdrop,
.breadcrumb-3 ol {
  justify-content: center;
  display: flex;
}

*::-ms-backdrop,
html.fullscreen {
  width: 100%;
}

*::-ms-backdrop,
html.fullscreen .main-content {
  overflow: scroll;
  overflow-x: hidden;
  height: 100vh;
}

*::-ms-backdrop,
.owl-item .wd-30,
.table-responsive-sm .wd-30 {
  width: inherit;
}

*::-ms-backdrop,
.table-responsive .media-body {
  flex: inherit;
}

*::-ms-backdrop,
body.horizontalmenu {
  display: flex;
  flex-direction: column;
}

/* ###### ie css ###### */

// .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
//   margin-left: 0px;
// }

.table-hover>tbody>tr:hover {
  --bs-table-accent-bg: transparent;
}

.blockquote-footer {
  margin-top: 0rem;
}

.table> :not(caption)>*>* {
  background-color: transparent;
  box-shadow: none;
}

.table-responsive .table> :not(caption)>*>* {
  border-bottom-width: 1px !important;
}

.card-table.table> :not(caption)>*>* {
  border-bottom-width: 0px !important;
}

.alert .btn-close {
  position: absolute;
  top: 3px;
  right: 15px;
  color: inherit;
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  background: none;
  font-size: 1.5rem;
  text-shadow: none;
  opacity: 0.7;
  transition: 0.3s color;
}

.btn-close:focus {
  box-shadow: none;
}

.btn-close:hover {
  color: inherit;
}

.breadcrumb-item+.breadcrumb-item::before {
  float: none;
}

.toast-header .btn-close {
  font-weight: 300;
  color: #a8afc7;
  margin-top: 4px;
  font-size: 10px;
}

// .input-group-text {
//   border-right: none !important;
// }

.crypto-buysell-card .select2-container--default .select2-selection--single {
  border-radius: 0 6px 6px 0 !important;
}

hr {
  opacity: 0.1;
}

.card-dashboard-calendar .table> :not(caption)>*>* {
  border-bottom-width: 0px !important;
}

.crypto-wallet .table> :not(caption)>*>* {
  border-bottom-width: 0px !important;
}

.form-select {
  padding: 0.175rem 2.25rem 0.175rem 0.75rem;
}

.hor-header.top-header {
  display: none;
}

.centerlogo-horizontal .header.top-header {
  display: none;
}

.centerlogo-horizontal .hor-header.top-header {
  display: block;
}

@media (max-width: 380px) {

  .btn,
  .sp-container button {
    font-size: 0.765rem;
    padding: 0.475rem 0.75rem;
  }

  .chartjs-wrapper-demo #chartDonut,
  .chartjs-wrapper-demo #chartPie {
    height: 220px !important;
    width: 250px !important;
  }
}

.input-group .btn.ripple.btn-primary {
  border-radius: 0 6px 6px 0 !important;
}

.carousel-indicators [data-bs-target] {
  width: 10px;
  height: 10px;
}

.card-img-left {
  width: 100%;
  border-bottom-left-radius: calc(5px - 1px);
  border-top-left-radius: calc(5px - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(5px - 1px);
  border-top-right-radius: calc(5px - 1px);
}

.file-manger-icon img {
  width: 70px;
  height: 70px;
}

.file-manger-icon {
  position: relative;
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.option-dots {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: transparent;
  margin: 0 auto;
  text-align: center;
  line-height: 45px;
  display: block;
}

.tag.tag-attachments {
  padding: 4px 15px 2px 15px;
  font-size: 15px;
  background-color: $white;
  border: 1px solid $border;
}

.tag.tag-attachments-lg {
  padding: 5px 17px 3px 17px;
  font-size: 17px;
  background-color: $white;
  border: 1px solid $border;
}

.tag.tag-attachments-sm {
  padding: 3px 10px 1px 10px;
  font-size: 13px;
  background-color: $white;
  border: 1px solid $border;
}

.tags .tag-files span {
  position: absolute;
  top: 21px;
  margin-right: 104px;
  left: 51px;
  font-size: 12px;
  color: #a5b1d9;
}

.attached-file-grid6 .icons li a {
  font-size: 13px;
  line-height: 30px;
  text-align: center;
  height: 30px;
  width: 30px;
  margin: 0 auto;
  border-radius: 5px;
  display: block;
  position: relative;
  transition: all 0.3s ease-in-out;
  background: $white;
}

.attached-file-grid6 .icons li a {
  color: $primary;
  border: 1px solid $white;
}

.attached-file-grid6 .icons {
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  left: 0;
  right: 0;
  text-align: center;
  position: absolute;
  bottom: -3px;
  z-index: 1;
  transition: all 0.5s ease 0s;
  display: block;
  margin: 0 auto;
  background-image: linear-gradient(to top, $black-5, transparent 100%);
}

.pro-img-box {
  position: relative;
}

.attached-file-grid6 .icons li {
  margin: 0 auto;
  display: inline-block;
  opacity: 0;
  transition: all 0.4s ease;
}

.attached-file-grid6 .icons li a:hover {
  color: $white;
}

.attached-file-grid6 .icons li a:hover {
  border: $primary;
  background: $primary;
}

.attached-file-grid6 .file-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.attached-file-image .pic-1 {
  transition: all 0.3s ease;
}

.attached-file-image .pic-2 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease;
  bottom: 0;
  right: 0;
  margin: 0 auto;
}

.attached-file-grid6:hover .attached-file-image .pic-2 {
  opacity: 0;
}

.attached-file-grid6:hover .icons {
  opacity: 1;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  padding: 10px;
}

.attached-file-grid6:hover .icons li {
  opacity: 1;
}

.image-pic {
  position: absolute;
  right: 0;
  left: 0;
  top: 0px;
  color: $white;
  font-size: 17px;
  background-image: linear-gradient(to bottom, $black-5, transparent 100%);
  padding: 0px 0px 20px 10px;
  border-radius: 5px;
}

.theme-container .active {
  border: 1px solid $primary;
  padding: 5px 10px;
  background: white;
  border-radius: 5px;
  color: $primary;
}

.theme-container1 .active {
  border: 1px solid $secondary;
  padding: 5px 10px;
  background: white;
  border-radius: 5px;
  color: $secondary;
}

.theme-container2 .active {
  border: 1px solid #05c3fb;
  padding: 5px 10px;
  background: white;
  border-radius: 5px;
  color: #05c3fb;
}

@media (max-width: 420px) {
  .pcr-app[data-theme="classic"] {
    max-width: 70vw !important;
  }
}

.show-entries .select2-container {
  width: 60px !important;
}

.filter-group .select2-container {
  width: 90px !important;
}

.productdesc .select2-container {
  width: 100px !important;
}

div.dataTables_wrapper div.dataTables_length label {
  .select2-container {
    width: 50% !important;
  }
}

#example-input {
  .select2-container {
    width: 100% !important;
  }
}

@media (min-width: 992px) and (max-width: 1300px) {
  .nav-sub.nav-sub-mega .container {
    max-width: 100%;
  }
}

.table-responsive .table> :not(caption)>*>* {
  border-bottom: 1px solid $border  !important;
}

.dataTable {
  &.table> :not(caption)>*>* {
    border-bottom: 0 !important;
  }
}

.pagination-radius .page-item+.page-item {
  margin-left: 5px;
}

.dark-layout {
  display: none;
}

.light-layout {
  display: block;
}

@media (min-width: 992px) {
  .horizontalmenu .hor-logo {
    display: block;
  }
}

.hor-logo {
  display: none;
}

.horizontalmenu .main-header .main-header-left .main-logo .desktop-logo-dark {
  display: none;
}

#slide-right {
  position: absolute;
  right: 20px;
  left: auto;
}

#slide-left {
  position: absolute;
  left: 20px;
  right: auto;
}

.slide-left,
.slide-right {
  display: none;
}

@media (max-width: 767px) {

  .main-content.side-content .page-header,
  .main-content .page-header {
    padding-top: 120px !important;
  }
}

@media (max-width: 991px) {
  .page-header {
    display: block;
    padding-top: 60px;
  }
}

@media (max-width: 370px) {
  .main-header-right .nav-link.icon {
    padding: 0.6rem 0.3rem;
  }

}

.statistics-icon {
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 6px;
  margin-bottom: 5px;
}

.moto-icon {
  width: 30px;
  height: 30px;

  path[fill="#b2b1ff"],
  path[fill="#d8d8ff"] {
    fill: $primary-03;
  }

  path[fill="#6d7ee5"],
  path[fill="#8c8aff"] {
    fill: $primary-08;
  }

  circle {
    fill: $primary-03;
  }
}


.main-header-center {
  .form-control {
    border-color: $border;

    &:focus {
      background: transparent;
    }

    .select2-container--default .select2-selection--single {
      border-right: 0;
    }
  }
}

pre {
  background-color: #ecebfb;
}

.form-control {

  &:disabled,
  &[readonly] {
    background-color: #f6f6ff;
  }
}


.selectgroup-input:checked+.selectgroup-button {
  background: #e5e9f3;
}

.input-group-text {
  background-color: #f6f6ff;
  border: 1px solid $border;
}

.irs-min,
.irs-max {
  color: #78769a;
  background: #f2f1ff;
}

.select2-container--default {
  &.select2-container--disabled .select2-selection--single {
    background-color: #f2f1ff;
  }

  .select2-selection--single {
    background-color: $white;
    border: 1px solid $border;
  }
}

.ff_fileupload_wrap .ff_fileupload_dropzone {
  border-color: 2px dashed #dcd9f5;
  background-color: #f2f1ff;
}

.select2-container--default {
  .select2-results__option--highlighted[aria-selected] {
    background-color: #f8f9ff !important;
  }

  .select2-results__option[aria-selected="true"] {
    background-color: $light;
  }
}

.wizard>.steps {
  .current a {

    .number,
    &:hover .number,
    &:active .number {
      color: $white;
    }
  }

  a {

    .number,
    &:hover .number,
    &:active .number {
      color: #8f9cc0;
      background-color: $background;
    }
  }
}

.pricing-tabs .nav-price li .active {
  background: #ececfc;
}

.table-hover tbody tr:hover {
  background-color: #f8f8ff;
}

.select2-container--default .select2-selection--multiple {
  .select2-selection__choice {
    background-color: #e0e2f7;
    border-color: 1px solid #e0e2f7;
    color: $color;
  }

  .select2-selection__choice__remove {
    color: $color;
  }
}

.project .deadline {
  background: $background;
}

.peity {
  &:before {
    content: "5%";
    position: absolute;
  }

  position: relative;
}

.forth.circle .chart-circle-value.circle-style {
  background: #fcfcff;
}

.popover-secondary {
  background-color: $secondary;
  border-color: $secondary;
}

#crypto-donut {
  margin: 0 auto;
}

.handle-counter input:focus-visible {
  outline: none;
}

.img-responsive {
  border-radius: 11px;
}

.product-grid .price {
  display: inline-flex;
}

#myTabContent {
  #whishlist {
    .btn {
      i {
        display: inline-flex;
      }
    }
  }
}

.dtr-details {

  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border: 1px solid $border;
  }
}

@media screen and(max-width:400px) {
  #checkoutsteps .item .left {
    display: block;
  }
}

@media (min-width: 767px) {
  .wizard>.actions {
    padding: 20px 14px;
  }
}

@media (min-width: 992px) and (max-width: 1103px) {
  .table-filter {
    input {
      width: 100px;
    }

    .btn {
      height: 20px;
    }
  }
}

@media (max-width: 450px) {
  .ql-snow .ql-tooltip.ql-editing {
    left: 0 !important;
    right: 0 !important;
  }
  #background{
    p{
    font-size: 7px;
    }
  }
}